import 'promise-polyfill/src/polyfill';
import './modules/configurations/public-path';

class LazysizesConfig {
  init() {
    console.log('[Lazyload] Init configurations..');
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.lazyClass = 'lazyload';
    //page is optimized for fast onload event
    window.lazySizesConfig.loadMode = 1;
    // Initialize the expand factor as the 15% of the window height
    window.lazySizesConfig.expand = $(window).height() + 200;

    console.log('[Lazyload] Initialized with config', window.lazySizesConfig);
  }
}

let lazysizesConfig = new LazysizesConfig();
lazysizesConfig.init(); // Overriding the configurations

import(/* webpackChunkName: "lazysizes" */ 'lazysizes');

